@font-face {
  font-family: 'proxima-nova-thin';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/proxima/ProximaNovaT-Thin.woff") format('woff'), url("../fonts/proxima/ProximaNovaT-Thin.ttf") format('truetype');
}
@font-face {
  font-family: 'proxima-nova-regular';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/proxima/ProximaNova-Regular.woff") format('woff'), url("../fonts/proxima/ProximaNova-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'proxima-nova-light';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/proxima/ProximaNova-Light.woff") format('woff'), url("../fonts/proxima/ProximaNova-Light.ttf") format('truetype');
}
@font-face {
  font-family: 'proxima-nova-semibold';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/proxima/ProximaNova-Semibold.woff") format('woff'), url("../fonts/proxima/ProximaNova-Semibold.ttf") format('truetype');
}
@font-face {
  font-family: 'proxima-nova-bold';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/proxima/ProximaNova-Bold.woff") format('woff'), url("../fonts/proxima/ProximaNova-Bold.ttf") format('truetype');
}
@font-face {
  font-family: 'proxima-nova-extrabold';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/proxima/ProximaNova-Extrabld.woff") format('woff'), url("../fonts/proxima/ProximaNova-Extrabld.ttf") format('truetype');
}
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6,
p,
em,
strong,
abbr,
acronym,
blockquote,
q,
cite,
ins,
del,
dfn,
a,
div,
span,
pre,
hr,
address,
br,
b,
i,
sub,
big,
small,
tt,
table,
tr,
caption,
thead,
tbody,
tfoot,
col,
colgroup,
form,
input,
label,
textarea,
button,
fieldset,
legend,
select,
option,
ul,
ol,
li,
dl,
dt,
dd,
code,
var,
samp,
img,
object,
param,
map,
area {
  padding: 0;
  margin: 0;
  border: none;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
header,
footer,
section,
aside,
figure,
figcaption,
input {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 15px;
  vertical-align: baseline;
  background: transparent;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input[type=button],
input[type=submit],
button {
  cursor: pointer;
}
button {
  border: 0;
}
:focus {
  outline: none;
}
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: none;
  outline: none;
}
input::-ms-clear {
  width: 0;
  height: 0;
}
input,
textarea {
  resize: none;
  user-select: text;
}
::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}
::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}
a {
  text-decoration: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
input,
select,
textarea {
  border-radius: 0;
  box-shadow: none;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.owl-item {
  transform: translateZ(0);
}
img {
  display: block;
}
button {
  background: transparent;
}
input,
textarea {
  font-family: 'proxima-nova-regular';
  -webkit-appearance: none;
  border-radius: 0;
}
html,
body {
  height: 100%;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  font-family: 'proxima-nova-regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 8px;
}
html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: #4e4e50;
  border-radius: 10px;
}
* {
  margin: 0;
  padding: 0;
}
header,
footer,
section {
  max-width: 100vw;
  overflow-x: hidden;
}
html,
body {
  width: 100%;
  height: 100%;
}
body {
  max-width: 100vw;
  overflow-x: hidden;
}
.b-wrapper {
  width: 100%;
  display: table;
  height: 100%;
  overflow: hidden;
}
.content {
  display: table-row;
  width: 100%;
  height: 100%;
}
.section {
  width: 100%;
}
.hidden {
  display: none !important;
}
section .container {
  padding-top: 136px;
  padding-bottom: 136px;
  position: relative;
}
@media only screen and (max-width: 1700px) {
  section .container {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}
@media only screen and (max-width: 991px) {
  section .container {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  section .container {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
section .container.to-right {
  padding-left: 10%;
}
@media only screen and (max-width: 1400px) {
  section .container.to-right {
    padding-left: 8%;
  }
}
@media only screen and (max-width: 991px) {
  section .container.to-right {
    padding-left: 30px;
  }
}
section .container.bgc-white {
  background-color: #fff;
}
section .container.bgc-white h2,
section .container.bgc-white h3,
section .container.bgc-white h4,
section .container.bgc-white h5,
section .container.bgc-white h6,
section .container.bgc-white .h3,
section .container.bgc-white .h4,
section .container.bgc-white .h5,
section .container.bgc-white .h6,
section .container.bgc-white .section-description {
  color: #000;
}
section .container.bgc-white:after {
  background-color: #fff;
}
section .container.bgc-black {
  background-color: #14171c;
}
section .container:after {
  position: absolute;
  content: '';
  display: block;
  width: 2000px;
  height: 100%;
  right: -1999px;
  top: 0;
}
.section_black {
  background-color: #000 !important;
  background: url("/assets/images/img/bg_black.webp") center;
}
.section_black h2,
.section_black h3,
.section_black h4,
.section_black h5,
.section_black h6,
.section_black .h3,
.section_black .h4,
.section_black .h5,
.section_black .h6,
.section_black .section-description,
.section_black ul li {
  color: #fff;
}
.section_white {
  background-color: #fff !important;
}
.section_white h2,
.section_white h3,
.section_white h4,
.section_white h5,
.section_white h6,
.section_white .h3,
.section_white .h4,
.section_white .h5,
.section_white .h6,
.section_white .section-description {
  color: #000;
}
.container {
  max-width: 1540px;
  padding-left: 95px;
  padding-right: 95px;
  width: 100%;
}
@media only screen and (max-width: 1700px) {
  .container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (max-width: 991px) {
  .container {
    width: 100%;
    padding-left: 30px !important;
    padding-right: 30px !important;
    max-width: inherit;
  }
}
@media only screen and (max-width: 600px) {
  .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.big-container .container {
  max-width: 1580px;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .big-container .container {
    width: 100%;
    padding-left: 30px !important;
    padding-right: 30px !important;
    max-width: inherit;
  }
}
@media only screen and (max-width: 600px) {
  .big-container .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.row {
  margin-bottom: 0;
}
.h1,
h1 {
  font-size: 74px;
  line-height: 82px;
  font-family: 'proxima-nova-bold';
  color: #fff;
}
@media only screen and (max-width: 1700px) {
  .h1,
  h1 {
    font-size: 72px;
    line-height: 80px;
  }
}
@media only screen and (max-width: 1400px) {
  .h1,
  h1 {
    font-size: 68px;
    line-height: 76px;
  }
}
@media only screen and (max-width: 1200px) {
  .h1,
  h1 {
    font-size: 64px;
    line-height: 72px;
  }
}
@media only screen and (max-width: 991px) {
  .h1,
  h1 {
    font-size: 58px;
    line-height: 62px;
  }
}
@media only screen and (max-width: 767px) {
  .h1,
  h1 {
    font-size: 48px;
    line-height: 52px;
  }
}
@media only screen and (max-width: 400px) {
  .h1,
  h1 {
    font-size: 40px;
    line-height: 44px;
  }
}
.h2,
.h3,
h2,
h3 {
  font-size: 50px;
  line-height: 54px;
  font-family: 'proxima-nova-bold';
  color: #fff;
  margin-bottom: 36px;
}
@media only screen and (max-width: 1700px) {
  .h2,
  .h3,
  h2,
  h3 {
    font-size: 48px;
    line-height: 52px;
  }
}
@media only screen and (max-width: 1400px) {
  .h2,
  .h3,
  h2,
  h3 {
    font-size: 45px;
    line-height: 49px;
  }
}
@media only screen and (max-width: 1200px) {
  .h2,
  .h3,
  h2,
  h3 {
    font-size: 43px;
    line-height: 47px;
  }
}
@media only screen and (max-width: 991px) {
  .h2,
  .h3,
  h2,
  h3 {
    font-size: 40px;
    line-height: 44px;
  }
}
@media only screen and (max-width: 767px) {
  .h2,
  .h3,
  h2,
  h3 {
    font-size: 36px;
    line-height: 40px;
  }
}
.h4,
h4 span {
  font-size: 38px;
  line-height: 47px;
  font-family: 'proxima-nova-bold';
  color: #fff;
}
@media only screen and (max-width: 1700px) {
  .h4,
  h4 span {
    font-size: 36px;
    line-height: 45px;
  }
}
@media only screen and (max-width: 1400px) {
  .h4,
  h4 span {
    font-size: 33px;
    line-height: 42px;
  }
}
@media only screen and (max-width: 1200px) {
  .h4,
  h4 span {
    font-size: 31px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 991px) {
  .h4,
  h4 span {
    font-size: 29px;
    line-height: 38px;
  }
}
@media only screen and (max-width: 767px) {
  .h4,
  h4 span {
    font-size: 25px;
    line-height: 34px;
  }
}
.h5,
h5 {
  font-size: 28px;
  line-height: 34px;
  font-family: 'proxima-nova-bold';
  color: #000;
}
@media only screen and (max-width: 1700px) {
  .h5,
  h5 {
    font-size: 26px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 1400px) {
  .h5,
  h5 {
    font-size: 24px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .h5,
  h5 {
    font-size: 22px;
    line-height: 32px;
  }
}
.h6,
h6 {
  font-size: 26px;
  line-height: 36px;
  color: #000;
  font-family: 'proxima-nova-bold';
  margin: 14px 0;
}
@media only screen and (max-width: 1700px) {
  .h6,
  h6 {
    font-size: 24px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 1400px) {
  .h6,
  h6 {
    font-size: 22px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .h6,
  h6 {
    font-size: 20px;
    line-height: 28px;
  }
}
.section-description {
  font-size: 24px !important;
  line-height: 34px;
  color: #000;
  margin: 18px 0 23px;
  max-width: 830px;
}
@media only screen and (max-width: 1700px) {
  .section-description {
    font-size: 22px !important;
    line-height: 32px;
  }
}
@media only screen and (max-width: 1200px) {
  .section-description {
    font-size: 20px !important;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .section-description {
    font-size: 18px !important;
    line-height: 24px;
  }
}
.section-description a {
  font-size: 24px;
  line-height: 34px;
}
@media only screen and (max-width: 1700px) {
  .section-description a {
    font-size: 22px !important;
    line-height: 32px;
  }
}
@media only screen and (max-width: 1200px) {
  .section-description a {
    font-size: 20px !important;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .section-description a {
    font-size: 18px !important;
    line-height: 24px;
  }
}
.section-description span {
  color: #00c088;
  font-size: inherit;
  line-height: inherit;
}
.section-description strong {
  font-family: 'proxima-nova-bold';
  font-size: inherit;
  line-height: inherit;
}
.back-top__link,
a {
  color: #00c088;
  text-decoration: underline;
  font-size: 18px;
  line-height: 30px;
  transition: all 0.2s ease;
}
@media only screen and (max-width: 767px) {
  .back-top__link,
  a {
    font-size: 16px;
    line-height: 26px;
  }
}
.back-top__link:hover,
a:hover {
  text-decoration: none;
}
.back-top__link {
  display: inline-block;
}
strong {
  color: #000;
  font-family: 'proxima-nova-semibold';
  margin: 0 0 14px;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1400px) {
  strong {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  strong {
    font-size: 16px;
    line-height: 26px;
  }
}
p {
  color: #686868;
  margin: 0 0 14px;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1400px) {
  p {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  p {
    font-size: 16px;
    line-height: 26px;
  }
}
p a {
  font-size: 20px;
  line-height: 30px;
  text-decoration: none;
}
@media only screen and (max-width: 1400px) {
  p a {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  p a {
    font-size: 16px;
    line-height: 26px;
  }
}
ul.custom-list-green {
  margin: 0 0 20px;
}
ul.custom-list-green li {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 15px;
  position: relative;
  padding-left: 52px;
  color: #686868;
}
ul.custom-list-green li:before {
  content: '';
  display: block;
  position: absolute;
  width: 30px;
  height: 1px;
  background-color: #00c088;
  left: 0;
  top: 15px;
}
@media only screen and (max-width: 1400px) {
  ul.custom-list-green li {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  ul.custom-list-green li {
    font-size: 16px;
    line-height: 26px;
  }
}
.list_custom li {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 28px;
  position: relative;
  padding-left: 34px;
  color: #000;
}
.list_custom li:before {
  content: '';
  display: block;
  position: absolute;
  width: 14px;
  height: 2px;
  background-color: #00c088;
  left: 0;
  top: 15px;
}
@media only screen and (max-width: 1400px) {
  .list_custom li {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .list_custom li {
    font-size: 16px;
    line-height: 26px;
  }
}
.list_custom li a {
  font-size: 20px;
  line-height: 30px;
  text-decoration: none;
}
@media only screen and (max-width: 1400px) {
  .list_custom li a {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .list_custom li a {
    font-size: 16px;
    line-height: 26px;
  }
}
.list_check li {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 24px;
  position: relative;
  padding-left: 40px;
  color: #000;
}
.list_check li:before {
  content: '';
  display: block;
  position: absolute;
  width: 13px;
  height: 7px;
  left: 0;
  top: 9px;
  border: 2px solid #00c088;
  transform: rotate(-45deg);
  border-top: none;
  border-right: none;
}
@media only screen and (max-width: 1400px) {
  .list_check li {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .list_check li {
    font-size: 16px;
    line-height: 26px;
  }
}
.list_check li a {
  font-size: 20px;
  line-height: 30px;
  text-decoration: none;
}
@media only screen and (max-width: 1400px) {
  .list_check li a {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .list_check li a {
    font-size: 16px;
    line-height: 26px;
  }
}
.cards-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 30px 0;
}
@media only screen and (max-width: 1024px) {
  .cards-wrap {
    margin-top: 40px;
  }
}
.cards-wrap .card_custom {
  width: 32%;
  min-height: 580px;
  position: relative;
  perspective: 2000px;
  margin: 10px 0;
  text-decoration: none;
}
@media only screen and (max-width: 1300px) {
  .cards-wrap .card_custom {
    min-height: 610px;
  }
}
@media only screen and (max-width: 1024px) {
  .cards-wrap .card_custom {
    min-height: inherit;
    width: 100%;
  }
}
.cards-wrap .card_custom h4 {
  color: #00c088;
}
.cards-wrap .card_custom .front,
.cards-wrap .card_custom .back {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 50px 45px;
  display: flex;
  transition: 1s;
  backface-visibility: hidden;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1200px) {
  .cards-wrap .card_custom .front,
  .cards-wrap .card_custom .back {
    padding: 35px 30px;
  }
}
.cards-wrap .card_custom .front {
  border: 1px solid #00c088;
  background-color: rgba(0,0,0,0.4);
  color: #00c088;
  align-items: flex-end;
  box-sizing: border-box;
}
@media only screen and (max-width: 1024px) {
  .cards-wrap .card_custom .front {
    display: none;
  }
}
.cards-wrap .card_custom .front .front__image {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 80px;
}
.cards-wrap .card_custom .back {
  border: 1px solid #fff;
  background-color: #fff;
  transform: rotateY(180deg);
  box-sizing: border-box;
  position: relative;
}
.cards-wrap .card_custom .back .card__link {
  position: absolute;
  bottom: 40px;
  left: 45px;
  color: #00c088;
  text-decoration: underline;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 0;
}
@media only screen and (max-width: 1200px) {
  .cards-wrap .card_custom .back .card__link {
    bottom: 30px;
    left: 30px;
  }
}
@media only screen and (max-width: 1024px) {
  .cards-wrap .card_custom .back .card__link {
    position: static;
  }
}
.cards-wrap .card_custom .back .card__link:hover {
  text-decoration: none;
}
.cards-wrap .card_custom .back .back-text__wrap {
  margin-top: 20px;
}
@media only screen and (max-width: 1024px) {
  .cards-wrap .card_custom .back .back-text__wrap {
    margin-top: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .cards-wrap .card_custom .back {
    transform: rotateY(0deg) !important;
    position: relative;
  }
}
.cards-wrap .card_custom .back h4 {
  margin-bottom: 15px;
}
.cards-wrap .card_custom:hover .front {
  transform: rotateY(180deg);
}
.cards-wrap .card_custom:hover .back {
  transform: rotateY(360deg);
}
.preloader-wrapper {
  height: 64px;
  margin-left: 80px;
  display: none;
}
.preloader-wrapper svg {
  height: 100%;
}
.form-wrp {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form-wrp .form-group {
  width: 100%;
}
.form-wrp .form-group.form-group_half {
  width: 47%;
}
@media only screen and (max-width: 991px) {
  .form-wrp .form-group.form-group_half {
    width: 100%;
  }
}
.form-wrp label {
  display: block;
  margin-bottom: 6px;
  color: #000;
  font-size: 16px;
  line-height: 24px;
  font-family: 'proxima-nova-semibold';
  position: relative;
  top: 8px;
  transform: none !important;
}
.form-wrp textarea {
  resize: vertical;
  padding: 0 !important;
}
.form-wrp textarea {
  font-size: 20px;
  line-height: 24px;
  border-bottom: 2px solid #e0dfe0;
  width: 100%;
  height: 40px;
  min-height: auto;
  margin-top: 6px;
}
.form-wrp textarea:focus {
  border-bottom: 2px solid #e0dfe0 !important;
  box-shadow: none !important;
}
.form-wrp input {
  height: 40px !important;
  font-size: 20px;
  line-height: 35px;
  border-bottom: 2px solid #e0dfe0;
  width: 100%;
}
.form-wrp input.invalid {
  border-bottom: 2px solid #ee4323;
}
.form-wrp input.valid {
  border-bottom: 2px solid #00c088;
}
.form-wrp .input-field {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 30px;
}
@media only screen and (max-width: 991px) {
  .form-wrp .input-field {
    padding-bottom: 20px;
  }
}
.form-wrp .form-error {
  position: absolute;
  left: 0;
  bottom: 10px;
  color: #ee4323;
  font-size: 13px;
  line-height: 16px;
}
@media only screen and (max-width: 991px) {
  .form-wrp .form-error {
    bottom: 0;
  }
}
.form-wrp textarea + .form-error {
  bottom: 25px;
}
.form-wrp .checkboxes-policy-wrap label {
  position: relative;
}
.form-wrp .checkboxes-policy-wrap .checkbox-text {
  font-size: 16px;
  line-height: 20px;
  color: #959496;
  padding-left: 36px;
}
.form-wrp .checkboxes-policy-wrap a {
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
}
.form-wrp .checkboxes-policy-wrap .checkboxe-wrap {
  margin-bottom: 18px;
}
.form-wrp .checkboxes-policy-wrap .checkboxe-wrap .form-control.invalid + .checkbox-text:before {
  border: 2px solid #ee4323;
}
.form-wrp .checkboxes-policy-wrap .checkboxe-wrap .form-error {
  bottom: -17px;
  left: 35px;
}
[type="checkbox"]+span:not(.lever):before,
[type="checkbox"]:not(.filled-in)+span:not(.lever):after {
  width: 24px;
  height: 24px;
  border: 2px solid #e0dfe0;
}
[type="checkbox"]:checked+span:not(.lever):before {
  border-right: 2px solid #00c088;
  border-bottom: 2px solid #00c088;
}
.btn_green {
  width: 280px;
  height: 64px;
  background-color: #00c088;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_green:hover,
.btn_green:focus {
  background-color: #00c088;
}
.btn_green span {
  color: #fff;
  font-size: 24px;
  line-height: 35px;
  font-family: 'proxima-nova-semibold';
}
@media only screen and (max-width: 767px) {
  .btn_green span {
    font-size: 18px !important;
    line-height: 29px !important;
  }
}
@media only screen and (max-width: 767px) {
  .btn_green {
    width: 240px;
    height: 56px;
  }
}
.btn-wrap {
  margin-top: 27px;
  display: flex;
}
@media only screen and (max-width: 767px) {
  .btn-wrap {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
.btn-wrap .contact-form-bottom-button {
  width: 256px;
  height: 64px;
  background-color: #00c088;
  color: #fff;
  font-size: 24px;
  line-height: 35px;
  font-family: 'proxima-nova-semibold';
}
@media only screen and (max-width: 767px) {
  .btn-wrap .contact-form-bottom-button {
    font-size: 18px;
    line-height: 29px;
    width: 240px;
    height: 56px;
  }
}
.mobile-menu {
  width: 400px;
  padding: 30px 20px 80px;
  background-color: #000;
}
@media only screen and (max-width: 600px) {
  .mobile-menu {
    width: 100%;
  }
}
.mobile-menu .sidenav__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile-menu .sidenav__header .cancel__btn:hover {
  cursor: pointer;
}
.mobile-menu .sidenav__body {
  padding: 0 20px;
}
.mobile-menu .links__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}
.mobile-menu .links__list .link-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.mobile-menu .links__list .link-wrap:before {
  display: none;
}
.mobile-menu .links__list .menu-link {
  color: #fff;
}
.mobile-menu .links__list .menu-link:after {
  transform: none;
  top: 11px;
}
.mobile-menu .collapsible {
  width: 100%;
}
.mobile-menu .collapsible .link-wrap {
  line-height: inherit;
  display: inline-block;
}
.mobile-menu .collapsible .link-wrap.active .menu-link_mobile {
  color: #00c088;
}
.mobile-menu .collapsible .link-wrap.active .menu-link_mobile .arrow-image {
  transform: rotate(180deg);
}
.mobile-menu .collapsible .link-wrap.active .menu-link_mobile .arrow-image path {
  fill: #00c088;
}
.mobile-menu .collapsible .link-wrap .menu-link_mobile {
  display: inline-flex;
  justify-content: flex-start;
}
.mobile-menu .collapsible .collapsible-header {
  padding-left: 0;
}
.mobile-menu .collapsible .collapsible-body {
  background-color: transparent;
  padding-left: 20px;
}
.mobile-menu .collapsible .collapsible-body .link {
  margin-top: 15px;
}
.mobile-menu .collapsible .collapsible-body .link a {
  color: #fff;
  text-decoration: none;
  line-height: 20px;
  font-size: 15px;
}
.mobile-menu .collapsible .collapsible-body .link a:hover {
  color: #00c088;
}
.custom-slider_white {
  margin-top: 65px;
  padding-bottom: 50px;
  width: calc(100% + 32px);
  margin-bottom: 40px;
  position: relative;
}
@media only screen and (max-width: 991px) {
  .custom-slider_white {
    padding-bottom: 90px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .custom-slider_white {
    width: 100%;
  }
}
.custom-slider_white .slick-track {
  display: flex;
}
.custom-slider_white .prev-btn,
.custom-slider_white .next-btn {
  bottom: 0;
  border: 1px solid #b7b7b7 !important;
}
.custom-slider_white .prev-btn:hover,
.custom-slider_white .next-btn:hover {
  border: 1px solid #00c088 !important;
  background-color: #00c088 !important;
}
.custom-slider_white .prev-btn:hover svg g,
.custom-slider_white .next-btn:hover svg g {
  fill: #fff;
}
.custom-slider_white .prev-btn svg g,
.custom-slider_white .next-btn svg g {
  fill: #b7b7b7;
}
.custom-slider_white .slide {
  margin-right: 32px;
  text-decoration: none;
  height: inherit !important;
}
@media only screen and (max-width: 600px) {
  .custom-slider_white .slide {
    margin-right: 0;
  }
}
.slick-slider .prev-btn,
.slick-slider .next-btn {
  position: absolute;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  cursor: pointer;
  transition: all 0.2s ease;
}
.slick-slider .prev-btn:hover,
.slick-slider .next-btn:hover {
  background-color: rgba(255,255,255,0.2);
}
.slick-slider .prev-btn {
  bottom: -50px;
  left: 0;
  z-index: 2;
}
@media only screen and (max-width: 991px) {
  .slick-slider .prev-btn {
    bottom: -8px;
  }
}
.slick-slider .prev-btn svg {
  transform: rotate(180deg);
}
.slick-slider .prev-btn svg g {
  transition: all 0.2s ease;
}
.slick-slider .next-btn {
  left: 66px;
  bottom: -50px;
}
@media only screen and (max-width: 991px) {
  .slick-slider .next-btn {
    bottom: -8px;
  }
}
.slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.slick-dots li {
  position: relative;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 1px solid #686868;
  background-color: #fff;
  margin: 4px;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  z-index: 1;
}
.slick-dots li.slick-active {
  border: 1px solid #00c088;
  background-color: #00c088;
}
.slick-dots li button {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: transparent;
  z-index: 2;
}
.carousel .indicators .indicator-item {
  width: 10px;
  height: 10px;
  background-color: #fff;
  margin: 24px 5px;
  padding: 0;
}
.carousel .indicators .indicator-item:before {
  display: none;
}
.carousel .indicators .indicator-item.active {
  background-color: #00c088;
}
.pagination-wrap {
  position: absolute;
  left: 15px;
  top: -20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
@media only screen and (max-width: 991px) {
  .pagination-wrap {
    top: -40px;
  }
}
.pagination-wrap li {
  position: relative;
  padding-right: 34px;
  line-height: 21px;
}
.pagination-wrap li .pagination__link {
  text-decoration: none;
  transition: all 0.2s ease;
  line-height: 21px;
  font-size: 16px;
}
.pagination-wrap li .pagination__link span {
  color: #fff;
  font-size: 16px;
  line-height: 21px;
}
.pagination-wrap li:hover span {
  color: #00c088;
}
.pagination-wrap li:last-child span {
  color: #00c088;
}
.pagination-wrap li:not(:last-child):after {
  content: '/';
  position: absolute;
  right: 15px;
  top: 0;
  color: #fff;
}
.custom-tabs .tab__result-wrap {
  overflow: hidden;
}
.custom-tabs .tab__result {
  transform: translateX(-100%);
  transition: all 0.6s ease;
  height: 0;
  overflow: hidden;
}
.custom-tabs .tab__result.active {
  height: auto;
  transform: translateX(0);
  overflow: auto;
}
.copy-link__wrap {
  display: flex;
}
.copy-link__wrap .copy-block {
  margin-left: 8px;
  cursor: pointer;
  transition: 0.2s ease;
  position: relative;
  height: 20px;
  width: 16px;
}
.copy-link__wrap .copy-block:hover .tooltip__text {
  display: block;
  position: absolute;
  top: 24px;
  left: -60px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  background-color: #000;
  padding: 5px 10px 6px;
  width: 145px;
}
@media only screen and (max-width: 991px) {
  .copy-link__wrap .copy-block:hover .tooltip__text {
    display: none !important;
  }
}
.copy-link__wrap .copy-block.active-copy .copy__text {
  display: block;
  position: absolute;
  top: 24px;
  left: -30px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  background-color: #00c088;
  padding: 5px 10px 6px;
}
.copy-link__wrap .copy-block.active-copy .tooltip__text {
  display: none;
}
.copy-link__wrap .copy-block.active svg {
  transform: translate(-50%, -50%) scale(0.9, 0.9);
}
.copy-link__wrap .copy-block .tooltip__text,
.copy-link__wrap .copy-block .copy__text {
  display: none;
}
.copy-link__wrap .copy-block svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.copy-link__wrap a {
  color: #000;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
}
@media only screen and (max-width: 1200px) {
  .copy-link__wrap a {
    font-size: 16px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 991px) {
  .copy-link__wrap a {
    font-size: 14px;
    line-height: 18px;
  }
}
.more-link {
  text-decoration: none;
  position: relative;
  padding-bottom: 2px;
}
.more-link:after {
  content: '';
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #00c088;
  transition: all 0.2s ease;
}
.more-link:hover:after {
  width: 0;
}
.show-more__link {
  display: block;
  text-align: center;
  text-decoration: none;
  margin: 30px auto 0;
  padding: 14px 50px 15px;
  border: 1px solid #00c088;
  transition: all 0.2s ease;
}
.show-more__link.active {
  display: none;
}
.show-more__link:hover {
  background-color: #00c088;
}
.show-more__link:hover span {
  color: #fff;
}
.show-more__link:hover svg path {
  fill: #fff;
}
.show-more__link span {
  font-size: 18px;
  line-height: 18px;
  transition: all 0.2s ease;
  font-family: 'proxima-nova-semibold';
}
.show-more__link svg {
  margin-left: 8px;
  transform: scale(1.3);
}
.show-more__link svg path {
  transition: all 0.2s ease;
  fill: #00c088;
}
.modal {
  max-height: 90%;
  width: 70%;
  top: 5% !important;
}
@media only screen and (max-width: 991px) {
  .modal {
    width: 80%;
  }
}
@media only screen and (max-width: 600px) {
  .modal {
    width: 90%;
  }
}
.modal .modal-content {
  padding: 20px 40px 60px;
}
@media only screen and (max-width: 600px) {
  .modal .modal-content {
    padding: 20px 20px 60px;
  }
}
.modal .modal-content h2 {
  color: #00c088;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.modal .modal-content h3,
.modal .modal-content h4,
.modal .modal-content h5,
.modal .modal-content h6,
.modal .modal-content .h3,
.modal .modal-content .h4,
.modal .modal-content .h5,
.modal .modal-content .h6 {
  color: #000;
  margin-bottom: 20px;
  margin-top: 30px;
}
.modal .modal-content h3 span,
.modal .modal-content h4 span,
.modal .modal-content h5 span,
.modal .modal-content h6 span,
.modal .modal-content .h3 span,
.modal .modal-content .h4 span,
.modal .modal-content .h5 span,
.modal .modal-content .h6 span {
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
}
.modal .modal-content a {
  color: #00c088 !important;
  margin-left: 0 !important;
}
.modal .modal-cross-wrap {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  left: 0;
  top: 0;
  background-color: #fafafa;
  width: 100%;
  padding: 10px 0;
  z-index: 1004;
}
.modal .modal-cross-wrap .cross-link {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
}
.modal .modal-cross-wrap .cross-link:hover .cross-icon path {
  fill: #00c088;
}
.modal .modal-cross-wrap .cross-link .cross-icon path {
  fill: #686868;
}
.custom-top-scroll {
  position: fixed;
  top: 0;
  left: 0;
  height: 6px;
  background-color: #00c088;
  transition: all 0.2s ease;
  z-index: 1000;
}
#tidio-chat-iframe {
  max-height: calc(100vh - 70px) !important;
  margin-bottom: 60px !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}
.enter-y {
  transform: translateY(50px) rotate(2deg);
  transition: all 1.2s cubic-bezier(0.37, 0.01, 0, 0.98);
  opacity: 0;
}
.enter {
  transition: all 1.2s cubic-bezier(0.37, 0.01, 0, 0.98);
  opacity: 0;
}
.seen .enter {
  opacity: 1;
  transition-delay: 0.3s !important;
}
.seen .enter-y {
  opacity: 1;
  transform: translate(0) scale(1) rotate(0);
}
.seen .delay-2 {
  transition-delay: 0.1s !important;
}
.seen .delay-3 {
  transition-delay: 0.15s !important;
}
.seen .delay-4 {
  transition-delay: 0.2s !important;
}
.seen .delay-5 {
  transition-delay: 0.25s !important;
}
.seen .delay-6 {
  transition-delay: 0.3s !important;
}
.seen .delay-7 {
  transition-delay: 0.35s !important;
}
.seen .delay-8 {
  transition-delay: 0.4s !important;
}
.seen .delay-9 {
  transition-delay: 0.45s !important;
}
[v-cloak] {
  display: none !important;
}
button.mfp-close {
  width: 24px !important;
  height: 24px !important;
  content: '' !important;
  font-size: 0 !important;
  top: -32px !important;
  right: 8px !important;
  background-color: transparent !important;
}
button.mfp-close:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("/assets/images/svg/cross-icon.svg") center no-repeat;
  background-size: contain;
}
@media only screen and (max-width: 991px) {
  button.mfp-close {
    top: 16px !important;
    right: 40px !important;
  }
  button.mfp-close:after {
    background: url("/assets/images/svg/cross-icon-gray.svg") center no-repeat;
  }
}
@media only screen and (max-width: 600px) {
  button.mfp-close {
    right: 20px !important;
  }
}
.mfp-container {
  width: 66%;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 120px 72px 72px;
  background-color: #fff;
}
@media only screen and (max-width: 991px) {
  .mfp-container {
    width: 100%;
    height: auto;
    padding: 120px 40px 40px;
  }
}
@media only screen and (max-width: 800px) {
  .mfp-container {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 600px) {
  .mfp-container {
    padding: 120px 20px 20px;
  }
}
img.mfp-img {
  padding: 0 !important;
}
.mfp-figure:after {
  box-shadow: none !important;
  background-color: #fff !important;
}
.mfp-content {
  position: static;
}
.mfp-bottom-bar {
  bottom: auto !important;
  top: 58px !important;
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
}
.mfp-counter {
  left: 72px;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
}
@media only screen and (max-width: 991px) {
  .mfp-counter {
    left: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .mfp-counter {
    left: 20px;
  }
}
.mfp-counter::first-letter {
  color: #00c088;
}
button.mfp-arrow-left {
  opacity: 1 !important;
  transform-origin: 0 50% !important;
  top: 110px !important;
  right: 136px !important;
  transform: none;
  left: auto !important;
  width: 40px !important;
  height: 40px !important;
}
@media only screen and (max-width: 991px) {
  button.mfp-arrow-left {
    right: 104px !important;
  }
}
@media only screen and (max-width: 600px) {
  button.mfp-arrow-left {
    right: 84px !important;
  }
}
button.mfp-arrow-left:hover:before {
  background: url("/assets/images/svg/img-slide-arrow-gray-white.svg") center no-repeat;
  background-size: contain;
  transform: translate(-50%, -50%) rotate(-180deg);
}
button.mfp-arrow-left:hover:after {
  border: 1px solid #00c088;
  background-color: #00c088;
}
button.mfp-arrow-left:before {
  display: block;
  background: url("/assets/images/svg/img-slide-arrow-gray.svg") center no-repeat;
  background-size: contain;
  width: 8px;
  height: 13px;
  z-index: 2;
  border: none;
  opacity: 1 !important;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
button.mfp-arrow-left:after {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  opacity: 1 !important;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #969bab;
}
button.mfp-arrow-right {
  opacity: 1 !important;
  top: 110px !important;
  right: 72px !important;
  transform: none !important;
  transform-origin: none !important;
  width: 40px !important;
  height: 40px !important;
}
@media only screen and (max-width: 991px) {
  button.mfp-arrow-right {
    right: 40px !important;
  }
}
@media only screen and (max-width: 600px) {
  button.mfp-arrow-right {
    right: 20px !important;
  }
}
button.mfp-arrow-right:hover:before {
  background: url("/assets/images/svg/img-slide-arrow-gray-white.svg") center no-repeat;
  background-size: contain;
  transform: translate(-50%, -50%);
}
button.mfp-arrow-right:hover:after {
  border: 1px solid #00c088;
  background-color: #00c088;
}
button.mfp-arrow-right:before {
  display: block;
  background: url("/assets/images/svg/img-slide-arrow-gray.svg") center no-repeat;
  background-size: contain;
  width: 8px;
  height: 13px;
  z-index: 2;
  border: none;
  opacity: 1 !important;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-180deg);
}
button.mfp-arrow-right:after {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  opacity: 1 !important;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #969bab;
}
@media only screen and (max-width: 767px) {
  #modalContactPrivacy h3 {
    font-size: 30px;
    line-height: 38px;
  }
  #modalContactPrivacy ul.custom-list-green li {
    padding-left: 30px;
  }
  #modalContactPrivacy ul.custom-list-green li:before {
    height: 2px;
    width: 16px;
    top: 12px;
  }
}
.trustpilot-widget {
  margin-top: 24px !important;
}
.votebtn__modal-trigger {
  position: absolute;
  top: 340px;
  right: -40px;
  cursor: pointer;
  padding: 15px 40px;
  border-radius: 16px 16px 0px 0px;
  background: #ee2b1c;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: 'proxima-nova-semibold';
  line-height: 33px;
  z-index: 5;
  text-decoration: none;
  transform: rotateZ(-90deg);
  transition: all 0.3s ease;
  opacity: 0;
}
.votebtn__modal-trigger:hover {
  right: -32px;
}
.votebtn__modal-trigger span {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  pointer-events: none;
}
.votebtn__modal-trigger.active {
  opacity: 1;
}
.votebtn__modal {
  position: absolute;
  top: 200px;
  right: 24px;
  background-color: #fff;
  max-width: 380px;
  padding: 80px 40px 50px;
  transition: all 0.3s ease;
  z-index: 6;
  margin-left: 24px;
  opacity: 0;
  transform: translateX(100%);
}
.votebtn__modal.active {
  opacity: 1;
  transform: translateX(0);
}
.votebtn__modal-close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.votebtn__modal-close svg {
  max-width: 100%;
  max-height: 100%;
}
.votebtn__logo-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 22px;
}
.votebtn__logo-wrap img {
  max-width: 100%;
}
.votebtn__text {
  color: #000;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 35px;
}
.votebtn__text strong {
  font-size: inherit;
  color: inherit;
  line-height: inherit;
}
.votebtn__btn {
  cursor: pointer;
  padding: 15px 40px;
  background: #ee2b1c;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: 'proxima-nova-semibold';
  line-height: 33px;
  z-index: 5;
  text-decoration: none;
  transition: all 0.3s ease;
}
.votebtn__btn span {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
}
#CybotCookiebotDialog {
  max-width: 100vw !important;
}
.menu {
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #000;
  box-shadow: none;
  padding: 18px 0;
  z-index: 900;
}
@media only screen and (max-width: 991px) {
  .menu {
    padding: 15px 0;
  }
}
.menu.main-menu {
  background-color: transparent;
  transition: all 0.3s ease;
}
.menu.main-menu.not-top {
  background-color: #000;
}
.menu .container {
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1800px;
}
.menu .menu__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  line-height: normal;
  position: relative;
}
.menu .brand-logo {
  line-height: 0;
  position: relative;
  left: 0;
  transform: none;
}
.menu .menu__links-wrap {
  display: flex;
}
.menu .menu__links-wrap .mobile-menu__btn {
  height: auto;
  line-height: inherit;
  margin: 0;
  cursor: pointer;
}
.menu .menu__links-wrap .mobile-menu__btn .open-menu__icon-block {
  height: 18px;
  width: 26px;
  position: relative;
  cursor: pointer;
}
.menu .menu__links-wrap .mobile-menu__btn .open-menu__icon-block .line {
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
}
.menu .menu__links-wrap .mobile-menu__btn .open-menu__icon-block .line:nth-child(1) {
  left: 0;
  top: 0;
}
.menu .menu__links-wrap .mobile-menu__btn .open-menu__icon-block .line:nth-child(2) {
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.menu .menu__links-wrap .mobile-menu__btn .open-menu__icon-block .line:nth-child(3) {
  left: 0;
  bottom: 0;
}
.contact-link_menu {
  border: 2px solid #00c088;
  transition: all 0.2s ease !important;
}
.contact-link_menu:hover {
  background-color: #00c088;
  transition: all 0.2s ease !important;
}
.contact-link_menu:hover span {
  color: #fff;
}
.menu-link_mobile {
  font-size: 18px;
  line-height: 24px;
  padding-left: 0 !important;
}
.menu-link_mobile.contact-link_menu {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.menu-link_mobile.dropdown-link .arrow-image {
  top: 13px !important;
}
.search-icon-link {
  display: flex !important;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
  height: auto !important;
  line-height: inherit !important;
  margin: 0 !important;
}
@media only screen and (max-width: 1200px) {
  .search-icon-link {
    padding: 0 24px;
  }
}
.search-icon-link path {
  transition: all 0.2s ease;
}
.search-icon-link:hover path {
  fill: #00c088;
}
.menu-link {
  padding: 8px 24px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}
.menu-link span {
  font-size: 18px;
}
@media only screen and (max-width: 1380px) {
  .menu-link:not(.menu-link_mobile) {
    display: none;
  }
}
.menu-link.dropdown-link {
  padding-right: 24px;
}
.menu-link.dropdown-link .arrow-image {
  position: absolute;
  right: 8px;
  top: 23px;
}
.menu-link.dropdown-link .arrow-image path {
  transition: color 0.2s ease;
}
.menu-link:first-child {
  margin-left: 0;
}
.menu-link:last-child {
  margin-right: 0;
}
.desktop-menu .menu-link {
  transition: color 0.2s ease;
}
.desktop-menu .menu-link.active {
  color: #00c088;
}
.desktop-menu .menu-link.active .arrow-image {
  transform: rotate(180deg);
}
.desktop-menu .menu-link.active .arrow-image path {
  fill: #00c088;
}
.desktop-menu .menu-link:hover {
  color: #00c088;
}
.desktop-menu .menu-link:hover .arrow-image {
  transition: all 0.2s ease;
}
.desktop-menu .menu-link:hover .arrow-image path {
  fill: #00c088;
}
.dropdown-menu {
  display: none;
  position: fixed;
  left: 0;
  top: 82px;
  width: 100vw;
  padding: 32px 0;
  background-color: rgba(0,0,0,0.9);
  backdrop-filter: blur(6px);
}
.dropdown-menu#technologyMenu {
  padding-bottom: 50px;
}
.dropdown-menu.active {
  display: block;
}
.dropdown-menu .container {
  max-width: 1380px;
}
.dropdown-menu .dropdown-menu__content {
  display: flex;
}
@media only screen and (min-width: 1201px) {
  .dropdown-menu .dropdown-menu__content--half .links-wrap {
    justify-content: space-between;
  }
  .dropdown-menu .dropdown-menu__content--half .links-wrap .link {
    width: 48%;
  }
  .dropdown-menu .dropdown-menu__content--col .links-wrap {
    justify-content: space-between;
  }
  .dropdown-menu .dropdown-menu__content--col .links-wrap .links-col {
    width: 40%;
  }
  .dropdown-menu .dropdown-menu__content--col .links-wrap .links-col--left {
    width: 60%;
    padding-right: 16px;
  }
}
@media only screen and (min-width: 1201px) {
  .dropdown-menu .dropdown-menu__content--col .links-wrap .link a {
    font-size: 15px;
  }
}
@media only screen and (min-width: 1260px) {
  .dropdown-menu .dropdown-menu__content--col .links-wrap .link a {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1400px) {
  .dropdown-menu .dropdown-menu__content--col .links-wrap .link a {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1201px) {
  .dropdown-menu .dropdown-menu__content--half .links-wrap .link a {
    font-size: 15px;
  }
}
@media only screen and (min-width: 1260px) {
  .dropdown-menu .dropdown-menu__content--half .links-wrap .link a {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1400px) {
  .dropdown-menu .dropdown-menu__content--half .links-wrap .link a {
    font-size: 18px;
  }
}
.dropdown-menu .intro-block {
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px;
  display: none;
  align-items: center;
  justify-content: center;
}
.dropdown-menu .intro-block--overlay {
  position: relative;
}
.dropdown-menu .intro-block--overlay:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.72;
  z-index: 1;
}
.dropdown-menu .intro-block--overlay p {
  position: relative;
  z-index: 2;
}
.dropdown-menu .intro-block.active {
  display: flex;
}
.dropdown-menu .intro-block p {
  color: #fff;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
}
.dropdown-menu .links-wrap {
  display: flex;
  flex-wrap: wrap;
}
.dropdown-menu .links-wrap:before {
  display: none;
}
.dropdown-menu .links-wrap .link {
  width: 100%;
  margin-bottom: 8px;
}
.dropdown-menu .links-wrap .link a {
  text-decoration: none;
}
.dropdown-menu .links-wrap .link a:hover {
  color: #00c088;
}
.menu.not-top .dropdown-menu {
  top: 82px;
}
@media only screen and (max-width: 1380px) {
  .menu a.sidenav-trigger {
    display: block;
  }
}
.search-sidenav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #2c2e31;
  padding-top: 68px;
  padding-bottom: 38px;
  height: auto !important;
  transform: translateY(-150%);
}
.search-sidenav .form_search {
  position: relative;
  max-width: 890px;
}
.search-sidenav .input_search {
  border-bottom: 2px solid #474747;
  padding: 8px 0 8px 45px;
  position: relative;
  color: #fff;
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  height: auto !important;
}
@media only screen and (max-width: 600px) {
  .search-sidenav .input_search {
    font-size: 24px;
    line-height: 32px;
  }
}
.search-sidenav .input_search::placeholder {
  color: #6a6a6a;
}
.search-sidenav .submitButton {
  background-color: transparent;
  position: absolute;
  padding: 8px 13px;
  top: 12px;
  left: 0;
}
@media only screen and (max-width: 600px) {
  .search-sidenav .submitButton {
    top: 9px;
  }
}
.search-sidenav .submitButton svg path {
  transition: all 0.2s ease;
}
.search-sidenav .submitButton:hover svg path {
  fill: #fff;
}
.dropdown-menu.dropdown-menu--lang {
  max-width: 56px;
  left: auto;
  right: 176px;
  padding-top: 10px;
  padding-bottom: 10px;
  top: 60px;
  background-color: #2c2e30;
}
.dropdown-menu.dropdown-menu--lang .container {
  padding-left: 14px;
  padding-right: 14px;
}
.menu .dropdown-menu__arrow-img {
  transition: all 0.3s ease;
  width: 0;
}
.menu .dropdown-menu__arrow-img path {
  fill: #00c088;
}
.menu .links-col {
  position: relative;
}
.menu .dropdown-menu {
  overflow: hidden;
}
.menu .sub-block-theme {
  padding-left: 12px;
}
.menu .link:hover .dropdown-menu__arrow-img {
  width: 20px;
  margin-right: 16px;
}
.menu .sub-block {
  display: none;
  height: 1000px;
  position: absolute;
  padding-top: 32px;
  padding-left: 40px;
  margin-top: -32px;
  background: rgba(255,255,255,0.08);
  width: 2000px;
}
.menu .sub-block:before {
  content: '';
  display: block;
  position: absolute;
  width: 2px;
  height: 140px;
  left: 0;
  top: 32px;
  background-color: #00c088;
}
@media only screen and (min-width: 1380px) {
  .menu .sub-block.active {
    display: block;
  }
}
.menu .dropdown-menu__link--sub.active {
  color: #00c088;
}
.menu.menu--v2 .dropdown-menu.dropdown-menu--lang {
  padding-bottom: 0 !important;
  right: 145px;
  position: absolute;
}
@media only screen and (max-width: 1380px) {
  .menu.menu--v2 .dropdown-menu.dropdown-menu--lang {
    display: none;
  }
}
.menu.menu--v2 .desktop-menu--left-part {
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 1380px) {
  .menu.menu--v2 .dropdown__wrap .container {
    max-width: 1616px;
  }
  .menu.menu--v2 .sub-block {
    background: transparent;
    height: 100%;
    position: relative;
    width: auto;
  }
  .menu.menu--v2 .sub-block:before {
    height: 100%;
  }
  .menu.menu--v2 .sub-block .link {
    margin-bottom: 16px;
    position: relative;
  }
  .menu.menu--v2 .sub-block .link .new-sub {
    position: absolute;
    top: 4px;
    right: -44px;
    padding: 1px 4px;
    background: #00c088;
    border-radius: 4px;
    font-family: 'proxima-nova-semibold';
    font-size: 10px;
    line-height: 10px;
    color: #111;
  }
  .menu.menu--v2 .sub-block .link a {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #b5b5b5;
    position: relative;
  }
  .menu.menu--v2 .sub-block .link a:hover {
    color: #00c088;
  }
  .menu.menu--v2 .menu__links-wrap {
    align-items: center;
  }
  .menu.menu--v2 .container {
    max-width: 1640px;
  }
  .menu.menu--v2 .contact-link_menu {
    padding-left: 18px;
    padding-right: 18px;
  }
  .menu.menu--v2 .contact-link_menu span {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
  .menu.menu--v2 .search-icon-link {
    padding-right: 0 !important;
    padding-bottom: 2px !important;
  }
  .menu.menu--v2 .desktop-menu--left-part .menu__links-wrap {
    margin-left: 24px;
    margin-top: -2px;
  }
  .menu.menu--v2 .dropdown-menu {
    padding-bottom: 64px;
  }
  .menu.menu--v2 .dropdown-menu .links-wrap .link {
    margin-bottom: 16px;
  }
  .menu.menu--v2 .dropdown-menu .links-wrap .link .new-sub {
    position: absolute;
    top: 4px;
    right: -44px;
    padding: 1px 4px;
    background: #00c088;
    border-radius: 4px;
    font-family: 'proxima-nova-semibold';
    font-size: 10px;
    line-height: 10px;
    color: #111;
  }
  .menu.menu--v2 .dropdown-menu .links-wrap .link a {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    position: relative;
  }
  .menu.menu--v2 .dropdown-menu .dropdown-menu__content--col .links-wrap .links-col--left {
    width: 55% !important;
  }
  .menu.menu--v2 .dropdown-menu .dropdown-menu__content--col .links-wrap .links-col--left .sub-block-theme {
    margin-left: auto;
    max-width: 350px;
  }
  .menu.menu--v2 .dropdown-menu .dropdown-menu__content--col .links-wrap .links-col {
    width: 45%;
  }
  .menu.menu--v2 .dropdown-menu .dropdown-menu__links-wrap {
    order: 1;
    padding-right: 36px;
    display: flex;
  }
  .menu.menu--v2 .dropdown-menu .dropdown-menu__links-wrap .links-wrap {
    width: 100%;
  }
  .menu.menu--v2 .dropdown-menu .dropdown-menu__images-wrap {
    order: 2;
    padding-left: 36px;
  }
  .menu.menu--v2 .dropdown-menu .intro-block {
    height: 214px;
    padding: 40px;
  }
  .menu.menu--v2 .dropdown-menu .intro-block p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #fff;
  }
}
.s-contact {
  border-top: 1px solid #ddd;
}
.s-contact.hidden {
  display: none;
}
.s-contact .section-description,
.s-contact h2 {
  color: #000;
}
.s-contact .addition-blocks-wrap {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .s-contact .addition-blocks-wrap {
    flex-wrap: wrap;
  }
}
.s-contact .addition-blocks-wrap .addition-block {
  display: flex;
  align-items: center;
  margin-right: 120px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .s-contact .addition-blocks-wrap .addition-block {
    margin-right: 0;
    width: 100%;
  }
}
.s-contact .addition-blocks-wrap .addition-block:last-child {
  margin-right: 0;
}
.s-contact .addition-blocks-wrap .addition-block img {
  margin-right: 16px;
}
.s-contact .addition-blocks-wrap .addition-block p {
  font-family: 'proxima-nova-semibold';
  margin: 0;
}
.s-contact .form-bottom {
  margin-top: 30px;
}
@media only screen and (max-width: 991px) {
  .s-contact .form-bottom {
    margin-right: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .s-contact .form-bottom {
    margin-right: 0;
  }
}
.s-contact .contact-description {
  position: relative;
  margin-top: 102px;
}
@media only screen and (max-width: 767px) {
  .s-contact .contact-description {
    margin-top: 28px;
  }
}
.s-contact .contact-description:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
  position: absolute;
  left: 64px;
  bottom: 64px;
  z-index: 1;
}
@media only screen and (max-width: 1700px) {
  .s-contact .contact-description:after {
    left: 30px;
    bottom: 30px;
  }
}
.s-contact .contact-description .h5,
.s-contact .contact-description h5 {
  margin-bottom: 10px;
  width: 100%;
}
.s-contact .contact-description .description-wrap {
  border: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 50px 60px;
  z-index: 2;
  position: relative;
  background-color: #fff;
}
@media only screen and (max-width: 1700px) {
  .s-contact .contact-description .description-wrap {
    padding: 50px 50px;
  }
}
@media only screen and (max-width: 991px) {
  .s-contact .contact-description .description-wrap {
    padding: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .s-contact .contact-description .description-wrap {
    justify-content: flex-start;
  }
}
.s-contact .contact-description .description-wrap .description-block {
  margin-top: 30px;
}
@media only screen and (max-width: 991px) {
  .s-contact .contact-description .description-wrap .description-block {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .s-contact .contact-description .description-wrap .description-block {
    padding-right: 20px;
  }
  .s-contact .contact-description .description-wrap .description-block:last-child {
    padding-right: 0;
  }
}
.s-contact .contact-description .description-wrap .description-block .header {
  font-family: 'proxima-nova-semibold';
  color: #000;
  margin-bottom: 5px;
}
@media only screen and (max-width: 1200px) {
  .s-contact .contact-description .description-wrap .description-block .header {
    margin-bottom: 0;
  }
}
.s-contact.s-contact-new h2 {
  max-width: inherit;
}
@media only screen and (max-width: 767px) {
  .s-contact.s-contact-new .info-content {
    order: 1;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .s-contact.s-contact-new .form-content {
    order: 2;
  }
}
@media only screen and (max-width: 767px) {
  .s-contact.s-contact-new .s-contact-new-wrap {
    display: flex;
    flex-wrap: wrap;
  }
}
.s-contact.s-contact-new .form-wrp label {
  margin-bottom: 16px;
}
.s-contact.s-contact-new .form-wrp [type="checkbox"]:checked+span:not(.lever) {
  color: #02182e;
  font-family: proxima-nova-semibold;
}
.s-contact.s-contact-new .form-wrp [type="checkbox"]+span {
  color: #6a707f;
  font-family: proxima-nova-regular;
}
.s-contact.s-contact-new .form-wrp [type="checkbox"]:checked+span:not(.lever):after {
  transform: none;
  border-color: #00c088;
  background-color: #00c088;
}
.s-contact.s-contact-new .form-wrp [type="checkbox"]:checked+span:not(.lever):before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  width: 8px;
  height: 14px;
  z-index: 2;
  top: 3px;
  left: 3px;
}
@media only screen and (max-width: 767px) {
  .s-contact.s-contact-new .s-contact-new__info-content {
    display: flex;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact.s-contact-new .s-contact-new__info-content {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 767px) {
  .s-contact.s-contact-new .s-contact-new__text-wrap {
    width: calc(100% - 226px);
  }
}
@media only screen and (max-width: 600px) {
  .s-contact.s-contact-new .s-contact-new__text-wrap {
    width: 100%;
  }
}
.s-contact.s-contact-new .s-contact-new__info-wrap {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .s-contact.s-contact-new .s-contact-new__info-wrap {
    margin-top: 16px;
    justify-content: flex-start;
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact.s-contact-new .s-contact-new__info-wrap {
    margin-top: 0;
  }
}
.s-contact.s-contact-new .s-contact-new__photo-wrap {
  margin-bottom: 32px;
}
@media only screen and (max-width: 767px) {
  .s-contact.s-contact-new .s-contact-new__photo-wrap {
    width: 186px;
    margin-right: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact.s-contact-new .s-contact-new__photo-wrap {
    width: 160px;
    margin-right: 0;
  }
}
.s-contact.s-contact-new .s-contact-new__photo-wrap img {
  max-width: 100%;
}
.s-contact.s-contact-new .s-contact-new__name {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #02182e;
  margin-bottom: 4px;
  font-family: proxima-nova-bold;
}
.s-contact.s-contact-new .s-contact-new__position {
  font-size: 18px;
  line-height: 28px;
  color: #6a707f;
  margin-bottom: 2px;
}
.s-contact.s-contact-new .s-contact-new__mail {
  font-size: 16px;
  line-height: 24px;
  color: #00c088;
  text-decoration: none;
}
.s-contact.s-contact-new .s-contact-new__info-blocks-wrap {
  margin-top: 24px;
}
.s-contact.s-contact-new .s-contact-new__info-block {
  margin-bottom: 4px;
}
.s-contact.s-contact-new .s-contact-new__info-block-title {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.1px;
  color: #02182e;
  margin-bottom: 2px;
  font-family: proxima-nova-bold;
}
.s-contact.s-contact-new .s-contact-new__info-block-link {
  font-size: 16px;
  line-height: 24px;
  color: #00c088;
  text-decoration: none;
}
@media only screen and (max-width: 767px) {
  .form-content,
  .description-content {
    width: 100% !important;
  }
  .s-contact .contact-description:after {
    display: none;
  }
}
.s-contact .s-contact-customers__customers-slide {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.s-contact .s-contact-customers__customers-photo {
  width: 234px;
  height: 234px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 17px solid #d3d4d5;
}
@media only screen and (max-width: 1200px) {
  .s-contact .s-contact-customers__customers-photo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 400px) {
  .s-contact .s-contact-customers__customers-photo {
    width: 190px;
    height: 190px;
  }
}
.s-contact .s-contact-customers__customers-photo img {
  max-width: 104%;
}
.s-contact .s-contact-customers__customers-slider {
  border: 1px solid #00c088;
  box-sizing: border-box;
  margin-left: 60px;
}
.s-contact .s-contact-customers__customers-slider:after {
  content: '';
  display: block;
  position: absolute;
  top: -12px;
  right: 80px;
  width: 55px;
  height: 22px;
  background: url("../../assets/images/img/dots-new.webp") center no-repeat;
  background-size: 32px;
  background-color: #fff;
}
@media only screen and (max-width: 992px) {
  .s-contact .s-contact-customers__customers-slider {
    margin-left: 0;
  }
}
.s-contact .s-contact-customers__customers-slide {
  padding: 50px;
}
@media only screen and (max-width: 500px) {
  .s-contact .s-contact-customers__customers-slide {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.s-contact .s-contact-customers__customers-content {
  width: calc(100% - 234px);
  padding-left: 16px;
}
@media only screen and (max-width: 1200px) {
  .s-contact .s-contact-customers__customers-content {
    width: 100%;
  }
}
.s-contact .s-contact-customers__customers-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 800;
  color: #2d2b2f;
  margin-bottom: 8px;
}
.s-contact .s-contact-customers__customers-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #2d2b2f;
  margin-bottom: 0;
}
.s-contact .s-contact-customers__customers-about {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 16px;
}
.s-contact .s-contact-customers__customers-name {
  font-size: 16px;
  line-height: 20px;
  color: #2d2b2f;
  margin-bottom: 4px;
}
.s-contact .s-contact-customers__customers-position {
  font-size: 16px;
  line-height: 20px;
  color: #2d2b2f;
  margin-bottom: 0;
}
.s-contact .s-contact-customers__customers-logo {
  max-width: 90px;
}
.s-contact .s-contact-customers__customers-logo img {
  max-width: 100%;
}
.s-contact .s-contact-customers__country {
  margin-left: 60px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 992px) {
  .s-contact .s-contact-customers__country {
    margin-left: 0;
  }
}
.s-contact .s-contact-customers__country-text-wrap {
  margin-top: 16px;
  padding-bottom: 16px;
}
.s-contact .s-contact-customers__country-text-wrap:first-child {
  border-bottom: 1.5px solid #e0dfe0;
}
.s-contact .s-contact-customers__country-text-wrap strong {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #2d2b2f;
}
.s-contact .s-contact-customers__country-text-wrap p {
  margin: 0 0 4px 0;
  font-size: 16px;
  line-height: 24px;
  color: #2d2b2f;
}
.s-contact .s-contact-customers__country-text-wrap .s-contact-customers__country-text--email-wrap {
  display: flex;
  flex-wrap: wrap;
}
.s-contact .s-contact-customers__country-text-wrap .s-contact-customers__country-text--email-wrap p {
  margin-right: 5px;
}
.s-contact .s-contact-customers__country-text-wrap .copy-link__wrap a {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2d2b2f;
}
.s-contact .s-contact-customers__tel-btn {
  margin: 20px 0;
  display: flex;
  align-items: center;
  padding: 8px 36px;
  width: 260px;
  height: 62px;
  border: 1px solid #00c088;
  text-decoration: none;
}
.s-contact .s-contact-customers__tel-btn img {
  margin-right: 20px;
}
.s-contact .s-contact-customers__tel-btn span {
  font-size: 16px;
  line-height: 24px;
  color: #2d2b2f;
  text-align: center;
}
.s-contact-customers {
  border-top: none;
}
.s-contact-customers .slick-slider .prev-btn,
.s-contact-customers .slick-slider .next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 60px;
  width: 24px;
  background-color: #fff;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}
.s-contact-customers .slick-slider .prev-btn img,
.s-contact-customers .slick-slider .next-btn img {
  height: 46px;
}
.s-contact-customers .slick-slider .prev-btn {
  left: -14px;
}
.s-contact-customers .slick-slider .next-btn {
  right: -14px;
  left: auto;
}
@media only screen and (max-width: 767px) {
  .s-contact-customers .s-contact-customers__customers {
    width: 100% !important;
    margin-top: 20px;
  }
}
.s-404 {
  padding-top: 60px;
}
.s-404 .container {
  margin-left: auto;
}
.s-404 .s-404__content .s-404__top-image {
  position: relative;
  overflow: hidden;
  max-height: 350px;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
}
.s-404 .s-404__content .s-404__top-image img {
  position: relative;
  z-index: 2;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.s-404 .s-404__content .s-404__top-image .s-404__video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.s-404 .s-404__content .s-404__top-image .s-404__video-bg video {
  width: 100%;
}
.s-404 .s-404__content .s-404__text-wrap {
  margin-top: 60px;
}
.s-404 .s-404__content .s-404__text-wrap .h4,
.s-404 .s-404__content .s-404__text-wrap h4 {
  text-align: center;
}
.s-404 .s-404__content .s-404__text-wrap p {
  margin-top: 28px;
  max-width: 529px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.s-404 .s-404__content .btn_green {
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.s-404 .s-404__content .btn_green span {
  font-size: 20px;
  line-height: 30px;
}
.s-footer {
  padding: 70px 0 50px;
  background: url("/assets/images/img/bg_black.webp");
  background-color: #000 !important;
}
.s-footer .copyright-wrap {
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #e6e6e6;
}
@media only screen and (max-width: 860px) {
  .s-footer .copyright-wrap {
    flex-wrap: wrap;
  }
  .s-footer .copyright-wrap .left-part {
    width: 100%;
    order: 2;
  }
  .s-footer .copyright-wrap .rigth-part {
    order: 1;
  }
}
.s-footer .copyright-wrap .copyright__text {
  font-size: 18px;
  line-height: 26px;
  margin: 16px 0;
  display: block;
  color: #fff;
}
@media only screen and (max-width: 860px) {
  .s-footer .copyright-wrap .copyright__text {
    font-size: 16px;
    line-height: 24px;
  }
}
.s-footer .copyright-wrap .back-top__link,
.s-footer .copyright-wrap a {
  cursor: pointer;
  text-decoration: none;
  color: #00c088;
  margin-left: 46px;
  font-family: 'proxima-nova-semibold';
}
.s-footer .copyright-wrap .back-top__link:hover,
.s-footer .copyright-wrap a:hover {
  color: #00c088;
}
@media only screen and (max-width: 992px) {
  .s-footer .copyright-wrap .back-top__link,
  .s-footer .copyright-wrap a {
    margin-left: 20px;
  }
}
@media only screen and (max-width: 860px) {
  .s-footer .copyright-wrap .back-top__link,
  .s-footer .copyright-wrap a {
    margin-left: 0;
    margin-right: 20px;
  }
}
.s-footer .s-foote__top-block {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 860px) {
  .s-footer .s-foote__top-block {
    flex-wrap: wrap;
  }
}
.s-footer .s-foote__top-block .brand-logo {
  margin-bottom: 16px;
}
.s-footer .s-foote__top-block .brand__preheader {
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}
.s-footer .s-foote__top-block .articles-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
  max-width: 450px;
  padding-right: 30px;
}
@media only screen and (max-width: 860px) {
  .s-footer .s-foote__top-block .articles-wrap {
    width: 100%;
    max-width: inherit;
    justify-content: flex-start;
  }
}
.s-footer .s-foote__top-block .articles-wrap .article {
  text-decoration: none;
  color: #fff;
  width: 100%;
}
.s-footer .s-foote__top-block .articles-wrap .article .article__text {
  max-width: 400px;
  font-size: 18px;
  line-height: 26px;
  margin: 16px 0;
  display: block;
}
@media only screen and (max-width: 860px) {
  .s-footer .s-foote__top-block .articles-wrap .article .article__text {
    font-size: 16px;
    line-height: 24px;
  }
}
.s-footer .s-foote__top-block .articles-wrap .article .article__date {
  font-size: 14px;
  line-height: 21px;
  opacity: 0.4;
}
.s-footer .s-foote__top-block .menu_bottom {
  width: calc(100% - 440px);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  margin: 0 0 12px;
}
@media only screen and (max-width: 860px) {
  .s-footer .s-foote__top-block .menu_bottom {
    width: 100%;
    justify-content: flex-start;
  }
}
.s-footer .s-foote__top-block .menu_bottom .links-wrap_social {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  top: 0;
  right: 0;
}
@media only screen and (max-width: 860px) {
  .s-footer .s-foote__top-block .menu_bottom .links-wrap_social {
    justify-content: flex-start;
  }
}
.s-footer .s-foote__top-block .menu_bottom .links-wrap_social .link {
  margin: 0 0 16px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 860px) {
  .s-footer .s-foote__top-block .menu_bottom .links-wrap_social .link {
    margin: 16px 20px 16px 0;
  }
}
.s-footer .s-foote__top-block .menu_bottom .links-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
@media only screen and (max-width: 860px) {
  .s-footer .s-foote__top-block .menu_bottom .links-wrap {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.s-footer .s-foote__top-block .menu_bottom .links-wrap .link {
  margin: 16px 40px 16px 0;
  text-decoration: none;
  color: #fff;
}
.s-footer .s-foote__top-block .menu_bottom .links-wrap .link:last-child {
  margin-right: 0;
}
.s-footer .s-foote__top-block .menu_bottom .links-wrap .link:hover {
  color: #00c088;
}
@media only screen and (max-width: 991px) {
  .s-footer .s-foote__top-block .menu_bottom .links-wrap .link {
    margin: 16px 16px 16px 0;
  }
}
@media only screen and (max-width: 860px) {
  .s-footer .s-foote__top-block .menu_bottom .links-wrap .link {
    margin: 5px 16px 5px 0;
  }
}
.copyright {
  background-color: #f7f7f7;
  max-width: 100vw;
}
.copyright .copyright-logos-wrap {
  display: flex;
  justify-content: space-between;
  width: calc(100% + 40px);
  margin-left: -20px;
  padding: 30px 0;
}
@media only screen and (max-width: 1400px) {
  .copyright .copyright-logos-wrap {
    overflow: visible;
    justify-content: flex-start;
    width: 100%;
    margin-left: 0;
  }
}
.copyright .copyright-logos-wrap .slick-list {
  overflow: visible !important;
}
.copyright .copyright-logos-wrap .copyright-logo {
  height: 96px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1130px) {
  .copyright .copyright-logos-wrap .copyright-logo {
    margin: 20px 30px;
  }
}
.copyright .copyright-logos-wrap .copyright-logo img {
  height: 100%;
  opacity: 0.3;
  filter: grayscale(100%);
  transition: all 0.2s ease;
  max-width: 150px;
}
.copyright .copyright-logos-wrap .copyright-logo:hover img {
  opacity: 1;
  filter: grayscale(0);
}
.s-footer.short {
  display: none !important;
}
.copyright.short {
  background-color: #fff;
}
.copyright.short .copyright-logos-wrap {
  display: none !important;
}
.copyright.no-logos {
  background-color: #fff;
}
.copyright.no-logos .copyright-logos-wrap {
  display: none !important;
}
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSlider {
  height: inherit;
}
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSlider:before {
  border: none;
  top: 0;
}
#CybotCookiebotDialog [type="checkbox"] {
  pointer-events: auto;
}
#CybotCookiebotDialog #CybotCookiebotDialogPoweredbyCybot {
  display: none !important;
}
.s-rate .container {
  padding-top: 0;
  padding-bottom: 80px;
}
.s-rate__title {
  color: #000;
  text-align: center;
  margin-bottom: 36px;
  opacity: 0.8;
}
.s-rate__stars-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.s-rate__star {
  width: 40px;
  height: 40px;
  margin: 8px;
  background: url("/assets/images/svg/star-outlined.svg") center no-repeat;
  background-size: cover;
  cursor: pointer;
}
.s-rate__star.active {
  background: url("/assets/images/svg/star-filled.svg") center no-repeat;
  background-size: cover;
}
.s-rate .btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.s-rate .btn-wrap .submitButton[disabled] {
  cursor: not-allowed;
}
.s-rate .preloader-wrapper {
  margin-left: 0;
  width: auto;
}
.modal__stars-form {
  max-width: 760px;
}
.modal__stars-form .h4,
.modal__stars-form h4 {
  text-align: center;
}
.modal__stars-form .h4 span,
.modal__stars-form h4 span {
  color: inherit;
}
.modal__stars-form .modal__ok {
  position: relative;
  text-transform: uppercase;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 32px;
}
.modal__stars-form #cross-icon {
  fill: #000;
}
.modal__stars-form .s-rate__star:nth-child(1),
.modal__stars-form .s-rate__star:nth-child(2),
.modal__stars-form .s-rate__star:nth-child(3),
.modal__stars-form .s-rate__star:nth-child(4) {
  background: url("/assets/images/svg/star-filled.svg") center no-repeat;
  background-size: cover;
}
.modal__stars-form .s-rate__star:nth-child(5) {
  background: url("/assets/images/svg/star-half.svg") center no-repeat;
  background-size: cover;
}
.modal__stars-form .jq-star {
  margin: 0 6px;
  pointer-events: none;
}
